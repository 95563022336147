// dependencies
import React from "react";
import { ScrollView, AccessibilityRole } from "react-native";

// utils
import { noop } from "@cloudspire/legacy-shared/src/libraries";

type IResult = {};

type IGroupedResult =
  | IResult
  | {
      children: IGroupedResult[];
      [name: string]: any;
    };

type IProps = {
  resultList: IResult[];
  highlightedResult: React.Key;
  reduceResultList: (resultList: IResult) => IGroupedResult[];
  renderGroup: (params: { group: any }) => React.ReactNode;
  renderResult: (params: {
    result: any;
    props: {
      key: React.Key;
      depth: number;
      onHoverIn: () => void;
      onHoverOut: () => void;
      onPress: () => void;
    };
    highlighted: boolean;
  }) => React.ReactNode;
  onHoverIn: (key: React.Key) => void;
  onHoverOut: (key: React.Key) => void;
  onPress: (key: React.Key) => void;
};

class NestedResultList extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public constructor(props: IProps) {
    super(props);

    this.renderResult = this.renderResult.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
    this.renderGroupedResult = this.renderGroupedResult.bind(this);
  }

  private renderResult(result, depth = 0): React.ReactNode {
    const { renderResult, highlightedResult, onHoverIn, onHoverOut, onPress } =
      this.props;

    return renderResult({
      result,
      props: {
        key: result.key,
        depth,
        onHoverIn: () => {
          onHoverIn(result.key);
        },
        onHoverOut: () => {
          onHoverOut(result.key);
        },
        onPress: () => {
          onPress(result.key);
        },
      },
      highlighted: result.key === highlightedResult,
    });
  }

  private renderGroup(group, depth = 0): React.ReactNode {
    const { renderGroup } = this.props;

    return renderGroup({
      group: group.group,
      props: {
        children: group.children.map((result) =>
          this.renderGroupedResult(result, depth + 1)
        ),
      },
    });
  }

  private renderGroupedResult(groupedResult, depth = 0): React.ReactNode {
    if (Array.isArray(groupedResult.children)) {
      if (groupedResult.children.length > 0) {
        return this.renderGroup(groupedResult, depth);
      }

      return null;
    } else {
      return this.renderResult(groupedResult, depth);
    }
  }

  public render(): React.ReactNode {
    const { resultList, reduceResultList } = this.props;

    return (
      <ScrollView accessibilityRole={"listbox" as AccessibilityRole}>
        {resultList.reduce(reduceResultList, []).map(this.renderGroupedResult)}
      </ScrollView>
    );
  }
}

NestedResultList.defaultProps = {
  onHoverIn: noop,
  onHoverOut: noop,
  onPress: noop,
};

export default NestedResultList;
