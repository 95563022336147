// dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useThrottleFn } from "react-use";

// components
import AutoComplete, {
  Result,
  NestedResultList,
  Group,
} from "@cloudspire/legacy-resources/src/components/AutoComplete";
import { IconDestinationO } from "@cloudspire/legacy-svg-icon-library";

// constants
import { apiRouter } from "@cloudspire/legacy-shared/src/constants/router";

// contexts
import { useRequestContext } from "@cloudspire/legacy-resources/src/contexts/requestContext";

// libraries
import {
  request,
  generateUri,
  get,
} from "@cloudspire/legacy-shared/src/libraries";

type PropsType = {
  name: string;
  initialValue?: string;
  inputProps?: any;
  onSelectResult: (event: { target: { name: string; value: any } }) => void;
};

const AutoCompleteCity: React.FunctionComponent<PropsType> = (props) => {
  const { name, initialValue = "", inputProps, onSelectResult } = props;

  const intl = useIntl();

  const requestContext = useRequestContext();

  const [resultList, setResultList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(initialValue);

  useThrottleFn(
    (value) => {
      setLoading(true);

      if (value === "") {
        setLoading(false);
        setResultList([]);
        return;
      }

      request({
        url: generateUri({
          router: apiRouter,
          name: "Api.Place.Autocomplete",
          query: {
            term: value,
          },
        }),
        context: requestContext,
      })
        .then((response) => {
          setResultList(
            response.body.data.map((item, itemIndex) => ({
              ...item,
              key: get(item, "id", get(item, "place_id", itemIndex)),
              value:
                "hotel" === item?.type
                  ? item?.description
                  : `${item.city}, ${item.country}`,
            }))
          );

          setLoading(false);
        })
        .catch(() => {
          setResultList([]);
          setLoading(false);
        });
    },
    1000,
    [value] as any
  );

  return (
    <AutoComplete
      resultList={resultList}
      inputProps={inputProps}
      initialValue={initialValue}
      renderResultList={({ highlightedResult, resultList, props }) => {
        return (
          <NestedResultList
            {...props}
            highlightedResult={highlightedResult}
            resultList={resultList}
            reduceResultList={(groupedResultListAcc, result) => {
              const type = result.type;

              if (null != type) {
                let groupedResultType = groupedResultListAcc.find(
                  (groupedResult) => groupedResult.group.type === type
                );

                if (null == groupedResultType) {
                  // si le type n'existe pas, on l'ajoute
                  groupedResultType = {
                    group: {
                      type,
                    },
                    children: [],
                  };

                  groupedResultListAcc.push(groupedResultType);
                }

                groupedResultType.children.push(result);
              } else {
                groupedResultListAcc.push(result);
              }

              return groupedResultListAcc;
            }}
            renderGroup={({ group, props }) => {
              return (
                <Group
                  key={group.type}
                  {...props}
                  label={intl.formatMessage(
                    { id: "app.containers.autoCompleteCity.type" },
                    { type: group.type }
                  )}
                />
              );
            }}
            renderResult={({ result, props, highlighted }) => {
              return (
                <Result
                  {...props}
                  key={result.key}
                  result={result}
                  label={result.description}
                  highlighted={highlighted}
                />
              );
            }}
          />
        );
      }}
      loading={loading}
      placeholder={intl.formatMessage({
        id: "app.containers.autoCompleteCity.label",
      })}
      leftIcon={<IconDestinationO />}
      onSelectResult={(result) => {
        onSelectResult({ target: { name, value: result } });
      }}
      onChangeText={setValue}
    />
  );
};

export default AutoCompleteCity;
