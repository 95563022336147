// dependencies
import React, { Fragment } from "react";
import { View, StyleSheet } from "react-native";

// components
import Text from "../Text";

type IProps = {
  label: string;
};

const styles = StyleSheet.create({
  groupWrapper: {
    paddingTop: ".5em",
    paddingRight: ".5em",
    paddingBottom: ".5em",
    paddingLeft: ".5em",
  },
  group: {
    fontWeight: "700",
  },
});

const Group: React.FunctionComponent<IProps> = (props) => {
  const { label, children, ...attrs } = props;

  return (
    <Fragment>
      <View {...attrs} style={styles.groupWrapper}>
        <Text style={styles.group}>{label}</Text>
      </View>

      {children}
    </Fragment>
  );
};

export default Group;
