// dependencies
import React from "react";
import {
  StyleSheet,
  View,
  ViewStyle,
  findNodeHandle,
  AccessibilityRole,
} from "react-native";

// components
import Popover from "../Popover3";
import TextInput from "../TextInput2";
import ActivityIndicator from "../ActivityIndicator";

// utils
import { isEquivalent, noop } from "@cloudspire/legacy-shared/src/libraries";

export type IResult = {
  key: React.Key;
  value: string;
  [name: string]: any;
};

type PropsType = {
  resultList: IResult[];
  renderResultList: (params: {
    highlightedResult: React.Key;
    resultList: IResult[];
    props: {
      onPress: (key: React.Key) => void;
      onHoverIn: (key: React.Key) => void;
      onHoverOut: (key: React.Key) => void;
    };
  }) => JSX.Element;
  initialValue?: string;
  loading?: boolean;
  placeholder?: string;
  leftIcon;
  rightIcon;
  onChangeText?: (text: string) => void;
  onSelectResult?: (result: IResult) => void;
};

type IState = {
  value: string;
  highlightedResult: React.Key;
  focused: boolean;
  reference;
};

class AutoComplete extends React.Component<PropsType, IState> {
  public static defaultProps: Partial<PropsType>;

  private $nodes = {
    autoComplete: React.createRef<View>(),
    resultList: React.createRef(),
    textInput: React.createRef<typeof TextInput>(),
    view: React.createRef<View>(),
  };

  public constructor(props: PropsType) {
    super(props);

    this.state = {
      highlightedResult: null,
      focused: false,
      value: props.initialValue,
      reference: null,
    };

    this.renderResultListPopup = this.renderResultListPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePress = this.handlePress.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleHoverIn = this.handleHoverIn.bind(this);
    this.handleHoverOut = this.handleHoverOut.bind(this);
  }

  public componentDidMount(): void {
    this.initKeyDown();

    const reference = findNodeHandle(
      this.$nodes.view.current
    ) as unknown as HTMLElement;

    if (null !== reference && reference !== this.state.reference) {
      this.setState({ reference });
    }
  }

  public componentWillUnmount(): void {
    this.terminateMouseDown();
    this.terminateKeyDown();
  }

  public componentDidUpdate(prevProps: PropsType, prevState: IState): void {
    const { focused } = this.state;

    const reference = findNodeHandle(
      this.$nodes.view.current
    ) as unknown as HTMLElement;

    if (null !== reference && reference !== this.state.reference) {
      this.setState({ reference });
    }

    if (prevState.focused !== focused) {
      if (focused) {
        // active l'écoute des clics et du clavier lorsque le composant
        //   est ouvert.
        this.initMouseDown();
      } else {
        // désactive l'écoute des clics et du clavier lorsque le composant
        //   n'est pas ouvert afin de préserver les performances.
        this.terminateMouseDown();
      }
    }

    if (
      !isEquivalent(
        prevProps.resultList.map((r): React.Key => r.key),
        this.props.resultList.map((r): React.Key => r.key),
        false
      )
    ) {
      // si on modifie la liste des résultats, on reset la sélection
      this.setState({ highlightedResult: null });
    }
  }

  /**
   * Active l'écoute du clic de la souris.
   */
  private initMouseDown(): void {
    if (process.browser) {
      document.addEventListener("mousedown", this.handleMouseDown);
    }
  }

  /**
   * Désactive l'écoute du clic de la souris.
   */
  private terminateMouseDown(): void {
    if (process.browser) {
      document.removeEventListener("mousedown", this.handleMouseDown);
    }
  }

  /**
   * Active l'écoute du clavier.
   */
  private initKeyDown(): void {
    if (process.browser) {
      (
        findNodeHandle(
          this.$nodes.textInput.current as any
        ) as unknown as HTMLElement
      ).addEventListener("keydown", this.handleKeyDown);
    }
  }

  /**
   * Désactive l'écoute du clavier.
   */
  private terminateKeyDown(): void {
    if (process.browser) {
      (
        findNodeHandle(
          this.$nodes.textInput.current as any
        ) as unknown as HTMLElement
      ).removeEventListener("keydown", this.handleKeyDown);
    }
  }

  /**
   * Vérifie que la fermeture de la liste des options peut avoir lieue.
   * Pour cela, on s'assure que le clic s'effectue en dehors du composant (input et optionList).
   */
  private handleMouseDown(event: MouseEvent): void {
    const $textInput: HTMLElement = findNodeHandle(
      this.$nodes.textInput.current as any
    ) as unknown as HTMLElement;
    const $resultList: HTMLElement = findNodeHandle(
      this.$nodes.resultList.current as any
    ) as unknown as HTMLElement;

    if (
      (null == $textInput || !$textInput.contains(event.target as Node)) &&
      (null == $resultList || !$resultList.contains(event.target as Node))
    ) {
      this.setState({ focused: false, highlightedResult: null });
    }
  }

  private handleFocus(): void {
    this.setState({ focused: true });
  }

  private handlePress(key: React.Key): void {
    this.selectItem(key);
  }

  private handleHoverIn(key: React.Key): void {
    this.setState({
      highlightedResult: key,
    });
  }

  private handleHoverOut(): void {
    this.setState({
      highlightedResult: null,
    });
  }

  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      // si l'utilisateur clique sur "Entrée" alors qu'un élément est sélectionné, on le définit en tant que valeur du champs

      event.preventDefault();
      event.stopImmediatePropagation();

      const { highlightedResult } = this.state;

      if (null != highlightedResult) {
        this.selectItem(highlightedResult);
      }
    } else if (event.key === "ArrowDown") {
      // si l'utilisateur clique sur la flèche du bas, on doit sélectionner le résultat suivant

      event.preventDefault();
      event.stopImmediatePropagation();

      const { focused } = this.state;

      if (!focused) {
        this.setState({ focused: true });
      } else {
        const { resultList } = this.props;
        const { highlightedResult } = this.state;

        if (!(resultList.length > 0)) {
          return;
        }

        if (null == highlightedResult) {
          this.setState({ highlightedResult: resultList[0].key });
        } else {
          const previousIndex = resultList.findIndex(
            (result) => result.key === highlightedResult
          );

          this.setState({
            highlightedResult:
              resultList[(previousIndex + 1) % resultList.length].key,
          });
        }
      }
    } else if (event.key === "ArrowUp") {
      // si l'utilisateur clique sur la flèche du haut, on doit sélectionner le résultat précédent

      const { focused } = this.state;
      if (!focused) {
        this.setState({ focused: true });
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();

        const { resultList } = this.props;
        const { highlightedResult } = this.state;

        if (!(resultList.length > 0)) {
          return;
        }

        if (null == highlightedResult) {
          this.setState({
            highlightedResult: resultList[resultList.length - 1].key,
          });
        } else {
          const previousIndex = resultList.findIndex(
            (result) => result.key === highlightedResult
          );

          this.setState({
            highlightedResult:
              resultList[
                (previousIndex - 1 + resultList.length) % resultList.length
              ].key,
          });
        }
      }
    } else if (event.key === "Escape") {
      // Si l'utilisateur appuie sur "Échap" alors qu'il sélectionne un résultat, on vide le champs et on cache les résultats
      //  sinon, si aucun résultat n'est sélectionné, on laisse le comportement par défaut (à savoir, la désélection du champs)

      event.preventDefault();
      event.stopImmediatePropagation();

      const { highlightedResult } = this.state;

      if (null != highlightedResult) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ highlightedResult: null });
      } else {
        this.setState({ focused: false });
      }
    } else if (event.key === "Tab") {
      this.setState({ highlightedResult: null, focused: false });
    }
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { onChangeText } = this.props;

    const text = event.target.value;

    this.setState({ value: text });

    onChangeText(text);
  }

  private selectItem(key: React.Key): void {
    const { resultList, onSelectResult } = this.props;

    const result = resultList.find((result): boolean => result.key === key);

    this.setState({
      value: result.value,
      focused: false,
      highlightedResult: null,
    });

    onSelectResult(result);
  }

  private getStyles(): StyleSheet.NamedStyles<{
    resultListPopup: ViewStyle;
  }> {
    return StyleSheet.create({
      resultListPopup: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#9b9b9b",
        borderRadius: 3,
        backgroundColor: "#ffffff",
        cursor: "pointer",
      },
    });
  }

  private renderResultListPopup(styles): JSX.Element {
    const { resultList, renderResultList } = this.props;
    const { highlightedResult } = this.state;

    return (
      <View style={styles.resultListPopup} ref={this.$nodes.resultList}>
        {renderResultList({
          resultList,
          highlightedResult,
          props: {
            onPress: this.handlePress,
            onHoverIn: this.handleHoverIn,
            onHoverOut: this.handleHoverOut,
          },
        })}
      </View>
    );
  }

  public render(): JSX.Element {
    const { resultList, loading, placeholder, inputProps, leftIcon } =
      this.props;
    const { value, focused, reference } = this.state;

    const styles = this.getStyles();

    return (
      <Popover
        gutter={10}
        visible={focused && resultList.length > 0}
        reference={reference}
        container={
          <View
            ref={this.$nodes.view}
            accessibilityRole={"combobox" as AccessibilityRole}
            aria-autocomplete="list"
            aria-expanded={focused && resultList.length > 0}
            aria-haspopup="listbox"
          >
            <TextInput
              {...inputProps}
              ref={this.$nodes.textInput}
              label={placeholder}
              placeholder={placeholder}
              leftIcon={leftIcon}
              rightIcon={loading && <ActivityIndicator size="small" />}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              value={value}
            />
          </View>
        }
        popover={this.renderResultListPopup(styles)}
      />
    );
  }
}

AutoComplete.defaultProps = {
  resultList: [],
  initialValue: "",
  loading: false,
  renderResultList() {
    return null;
  },
  onChangeText: noop,
  onSelectResult: noop,
};

export default AutoComplete;
