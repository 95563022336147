// dependencies
import React from "react";
import { ScrollView, AccessibilityRole } from "react-native";

// utils
import { noop } from "@cloudspire/legacy-shared/src/libraries";

type IProps = {
  resultList: any[];
  highlightedResult: React.Key;
  renderResult: (params: {
    result: any;
    props: {
      key: React.Key;
      onHoverIn: () => void;
      onHoverOut: () => void;
      onPress: () => void;
    };
    highlighted: boolean;
  }) => JSX.Element;
  onHoverIn?: (key: React.Key) => void;
  onHoverOut?: (key: React.Key) => void;
  onPress?: (key: React.Key) => void;
};

const SimpleResultList: React.FunctionComponent<IProps> = (
  props
): JSX.Element => {
  const {
    resultList,
    highlightedResult,
    renderResult,
    onHoverIn,
    onHoverOut,
    onPress,
  } = props;

  return (
    <ScrollView accessibilityRole={"listbox" as AccessibilityRole}>
      {resultList.map((result) =>
        renderResult({
          result,
          props: {
            key: result.key,
            onHoverIn: () => {
              onHoverIn(result.key);
            },
            onHoverOut: () => {
              onHoverOut(result.key);
            },
            onPress: () => {
              onPress(result.key);
            },
          },
          highlighted: result.key === highlightedResult,
        })
      )}
    </ScrollView>
  );
};

SimpleResultList.defaultProps = {
  onHoverIn: noop,
  onHoverOut: noop,
  onPress: noop,
};

export default SimpleResultList;
